import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import Axios from "axios";

import "bootstrap";

import PortalVue from "portal-vue";
import "./registerServiceWorker";
Vue.use(PortalVue);

Vue.config.productionTip = false;

// set auth header
Axios.defaults.headers.common["Authorization"] = `Bearer ${store.state.token}`;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
