import axios from "axios";
import router from "../router";

const api = axios.create({
  baseURL: "https://testapi.englishtonic.com/api/",
  withCredentials: true
});

const apifunc = {
  login(user, password) {
    return api
      .post("login_check", { username: user, password: password })
      .then(response => {
        return response;
      });
  },
  async getUser(user_location) {
    return await api.get(user_location).then(response => response.data);
  },
  async getJobs() {
    return await api.get("job_positions.json").then(response => response.data);
  },
  async getMentors() {
    return await api
      .get("users.json?enabled=true&isMentor=true")
      .then(response => response.data)
      .catch(() => {
        router.push("Login");
      });
  },
  async getQuizList() {
    return await api
      .get("quizzes.jsonld?isPublished=true")
      .then(response => {
        console.log(response.data["hydra:member"]);
        return response.data["hydra:member"];
      })
      .catch(() => {
        router.push("Login");
      });
  },
  async getQuiz(n) {
    return await api
      .get("quizzes/" + n.toString() + ".json")
      .then(response => response.data)
      .catch(() => {
        console.error("Quiz", n, "does not exist!");
        router.push("Login");
      });
  },
  async getSurvivalKits() {
    return await api
      .get("survival_kits.json?isPublished=true")
      .then(response => response.data)
      .catch(() => {
        router.push("Login");
      });
  },
  async getSurvivalKit(n) {
    return await api
      .get("survival_kits/" + n.toString() + ".json")
      .then(response => response.data)
      .catch(() => {
        console.error("Survival Kit ", n, "does not exist!");
        router.push("Login");
      });
  }
  /* signUp(credentials) {
   *   return api
   *     .post(API_url + "sign-up/", credentials)
   *     .then(response => response.data);
   * } */
};

export default apifunc;
