<template>
  <div class="sidebar">
    <div class="sidebar-backdrop" v-if="isSidebarOpen"></div>
    <transition name="slide">
      <div v-if="isSidebarOpen" class="sidebar-panel">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
import app from "@/store/app.js";

export default {
  computed: {
    isSidebarOpen() {
      return app.sidebar_open;
    }
  }
};
</script>

<style lang="scss">
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.2s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
  transition: all 150ms ease-in 0s;
}

.sidebar-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  cursor: pointer;
  z-index: 2;
}

.sidebar-panel {
  overflow-y: auto;
  background-color: theme-color("background");
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  z-index: 999;
  padding: 3rem 20px 2rem 20px;
  width: 300px;
  @extend .p-0;

  ul {
    margin-top: 15vh;
    list-style-type: none;
    text-align: left;
  }

  ul > li > a {
    color: #fff;
    text-decoration: none;
    font-size: 1.5rem;
    display: block;
    padding-bottom: 0.5em;
  }
}
</style>
