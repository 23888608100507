<template>
  <div id="avatar">
    <img v-if="avatar" v-bind:src="avatar" alt="avatar" />
  </div>
</template>

<script>
export default {
  name: "Avatar",
  props: {
    user: {
      type: Object,
      default: null
    }
  },
  methods: {
    forceSlash: function(str) {
      if (str.substring(0, 4) !== "http" && str.charAt(0) !== "/")
        return "/" + str;
      else return str;
    }
  },
  computed: {
    avatar: function() {
      let user = this.user;
      if (user === null) user = this.$store.state.user;
      if (!!user && !!user.avatar && user.avatar.length > 0)
        return this.forceSlash(user.avatar);
      else return "/images/icons/person.svg";
    }
  }
};
</script>

<style lang="scss" scoped>
#avatar {
  position: static;
  img {
    height: inherit;
    padding: 0.2em;
  }
}
#badges {
  position: absolute;
  top: 8%;
  right: 10%;
  height: 20% !important;
  width: max-content;
  img {
    height: inherit;
  }
}
</style>
