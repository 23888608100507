import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import api from "@/services/api.js";

Vue.use(Vuex);

const getDefaultState = () => {
  return {
    // token: "",
    user: {},
    quizList: null,
    survivalKits: null
  };
};

export default new Vuex.Store({
  strict: true,
  plugins: [createPersistedState()],
  state: getDefaultState(),
  getters: {
    isLoggedIn: state => {
      return state.token;
    },
    getAvatar: state => {
      if (!!state.user && !!state.user.avatar && state.user.avatar.length > 0)
        return state.user.avatar;
      else return "/images/icons/person.svg";
    }
  },
  mutations: {
    SET_USER: (state, user) => {
      state.user = user;
      state.avatar = user.avatar;
    },
    SET_QUIZLIST: (state, quizList) => {
      state.quizList = quizList;
    },
    SET_SURVIVALKITS: (state, records) => {
      state.survivalKits = records;
    },
    RESET: state => {
      Object.assign(state, getDefaultState());
    }
  },
  actions: {
    async login(context, { location }) {
      const user = await api.getUser(location);
      context.commit("SET_USER", user);
      // set auth header
      // Axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    },
    logout: ({ commit }) => {
      commit("RESET", "");
    },
    async getSurvivalKits(context) {
      if (!context.state.survivalKits) {
        const sk = await api.getSurvivalKits();
        context.commit("SET_SURVIVALKITS", sk);
      }
    },
    async fillQuizList(context) {
      if (!context.state.quizList) {
        const quizList = await api.getQuizList();
        context.commit("SET_QUIZLIST", quizList);
      }
    }
  },
  modules: {}
});

/*
 *   state: {
 *     peermentoring: {
 *       filters: [
 *         {
 *           title: "I'm looking for feedback from",
 *           options: [
 *             {
 *               text: "<b>inside</b> my company"
 *             },
 *             {
 *               text: "<b>outside</b> my company"
 *             },
 *             {
 *               text: "a professional coach"
 *             }
 *           ]
 *         },
 *         {
 *           title: "Another filter",
 *           options: [
 *             {
 *               text: "Choice 1"
 *             },
 *             {
 *               text: "Choice 2"
 *             },
 *             {
 *               text: "The third choice"
 *             }
 *           ]
 *         },
 *         {
 *           title: "I'm blue",
 *           options: [
 *             {
 *               text: "Daba dee"
 *             },
 *             {
 *               text: "Daba da"
 *             },
 *             {
 *               text: "Yes"
 *             },
 *             {
 *               text: "No"
 *             }
 *           ]
 *         },
 *         {
 *           title: "blablabla",
 *           options: [
 *             {
 *               text: "Bli"
 *             },
 *             {
 *               text: "blu"
 *             }
 *           ]
 *         }
 *       ]
 *     } */
