import Vue from "vue";
import VueRouter from "vue-router";
import app from "@/store/app.js";
import store from "@/store/index.js";

/* import Home from "../views/Home.vue";
 *  */
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Main Menu",
    component: () =>
      import(/* webpackChunkName: "main" */ "@/views/MainMenu.vue")
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "main" */ "@/views/About.vue")
  },
  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "main" */ "@/views/Login.vue")
  },
  {
    path: "/logout",
    component: () => {
      console.log("Asking for logout");
      store.commit("RESET");
      if (router.currentRoute.path != "/") router.replace("/");
    },
    name: "Logout"
  },
  {
    path: "/user",
    name: "Profile",
    component: () => import(/* webpackChunkName: "main" */ "@/views/User.vue")
  },
  {
    path: "/quizmenu",
    name: "Quiz Menu",
    component: () => import("@/views/QuizMenu.vue")
  },
  {
    path: "/solo",
    name: "Solo Menu",
    component: () => import("@/views/SoloMenu.vue")
  },
  {
    path: "/quiz",
    name: "Random Quiz",
    component: () => import("@/views/Quiz.vue")
  },
  {
    path: "/quiz/:id",
    name: "Quiz",
    component: () => import("@/views/Quiz.vue")
  },
  {
    path: "/tlearning",
    name: "TLearning",
    component: () => import("@/views/TLearning.vue")
  },
  {
    path: "/peermentoring",
    name: "Peer Mentoring",
    component: () => import("@/views/PeerMentoring.vue")
  },
  {
    path: "/pmwizard",
    name: "New Peer Mentoring",
    component: () => import("@/views/PeerMentoringWizard.vue")
  },
  {
    path: "/pmsandwich/:id",
    name: "Peer Mentoring Sandwich Feedback",
    component: () => import("@/views/PeerMentoringSandwich.vue")
  },
  {
    path: "/survivalkit",
    name: "Survival Kit",
    component: () => import("@/views/SurvivalKit.vue")
  },
  {
    path: "/survivalrecord/:id",
    name: "Survival Record",
    component: () => import("@/views/SurvivalRecord.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  app.sidebar_open = false;
  /* console.debug("router beforeEach: to[%s] from[%s] next[%s]", to, from, next); */
  /* if (to.name !== "Login" && !isAuthenticated) next({ name: "About" });
   * else */ next();
});

export default router;
