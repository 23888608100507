<template>
  <div id="app">
    <div id="topbar">
      <div class="d-flex justify-content-between align-items-center">
        <div id="topbar-left">
          <portal-target name="topbar-menu">
            <Burger></Burger>
          </portal-target>
        </div>
        <div id="topbar-center">
          <portal-target name="topbar-text">
            <router-link to="/">
              <img id="logo" src="/images/themes/ET/LOGO_ET_2.png" />
            </router-link>
          </portal-target>
        </div>
        <div id="topbar-right">
          <portal-target name="topbar-img">
            <Avatar />
          </portal-target>
        </div>
      </div>
    </div>
    <Sidebar>
      <ul>
        <li>
          <router-link to="/">
            Home
          </router-link>
        </li>
        <li>
          <router-link to="/about">
            About
          </router-link>
        </li>
        <li>
          <router-link to="/survivalkit">
            Survival Kit
          </router-link>
        </li>
        <li>
          <router-link to="/peermentoring">
            Peer Mentoring
          </router-link>
        </li>
        <li>
          <router-link to="/quizmenu">
            Quiz
          </router-link>
        </li>
      </ul>
      <ul>
        <li>
          <router-link to="/user" v-if="isLoggedIn">
            Profile
          </router-link>
          <router-link to="/login" v-else>
            Log In
          </router-link>
        </li>
        <li>
          <router-link to="/logout" v-if="isLoggedIn">
            Disconnect
          </router-link>
        </li>
      </ul>
    </Sidebar>
    <transition-page>
      <router-view />
    </transition-page>

    <footer>
      <portal-target name="footer-head" transition="fade">
        <transition name="fade" mode="out-in">
          <div class="container">
            <div class="row">
              <div class="col">
                <router-link
                  to="/survivalkit"
                  class="btn btn-background"
                  :class="isSKactive"
                >
                  <img src="/images/themes/ET/menu/fa-life-ring.svg" /><br />
                </router-link>
              </div>
              <div class="col">
                <router-link
                  to="/peermentoring"
                  class="btn btn-background"
                  :class="isPMactive"
                >
                  <img src="/images/themes/ET/menu/fa-comments-o.svg" /><br />
                </router-link>
              </div>

              <div class="col">
                <router-link
                  to="/quizmenu"
                  class="btn btn-background"
                  :class="isQGactive"
                >
                  <img src="/images/themes/ET/menu/fa-trophy.svg" /><br />
                </router-link>
              </div>
            </div>
          </div>
        </transition>
      </portal-target>
    </footer>
  </div>
</template>

<script>
import TransitionPage from "@/components/TransitionPage.vue";
import Burger from "@/components/Burger.vue";
import Sidebar from "@/components/Sidebar.vue";
import Avatar from "@/components/Avatar.vue";

export default {
  name: `App`,
  components: {
    TransitionPage,
    Burger,
    Sidebar,
    Avatar
  },
  data() {
    return {};
  },

  computed: {
    isLoggedIn() {
      return (
        typeof this.$store.state.user !== "undefined" &&
        Object.entries(this.$store.state.user).length > 0
      );
    },
    isPMactive() {
      if (this.$route.name == "Peer Mentoring") {
        return "active";
      } else {
        return "";
      }
    },
    isSKactive() {
      if (this.$route.name == "Survival Kit") {
        return "active";
      } else {
        return "";
      }
    },
    isQGactive() {
      if (this.$route.name == "Quiz Menu") {
        return "active";
      } else {
        return "";
      }
    }
  }
};
</script>

<style lang="scss">
@import "./style/animations.scss";

#app {
  margin-top: 15vh;
  margin-bottom: 10vh;
  @extend .bg-light-gray;
  height: 75vh;
  overflow: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#app {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#topbar {
  @extend .text-light;
  @extend .bg-background;

  /* avoid stretched link to go outside */
  /* @extend .position-relative;
     */
  @extend .fixed-top;
  height: 15vh;
  #topbar-left {
    max-width: 13vh;
    width: 12vh;
  }
  #topbar-center {
    height: 13vh !important;
    object-fit: contain;
  }

  #topbar-right {
    height: 13vh;
    text-align: right;
    @extend .img-fluid;
    @extend .figure-img;
    /* @extend .m-1; */
    @extend .rounded;
    @extend .h-100;
    @extend .px-1;
    @extend .h3;
    div {
      height: 13vh;
    }
  }

  #logo {
    height: 13vh !important;
  }
}

footer {
  @extend .text-center;
  @extend .text-muted;
  @extend .bg-background;
  @extend .shadow-lg;
  @extend .mt-2;
  @extend .fixed-bottom;
  height: 10vh;

  .col {
    padding: 0;
  }
  a {
    @extend .text-light;
    text-align: center;
    width: 100% !important;

    img {
      padding: 1vh;
      height: 9vh;
    }
  }
}
</style>
